<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="dialog-form-con"
    >
      <el-form-item label="应用名称" prop="navigateName">
        <el-input
          :disabled="ruleForm.isDefault == 1"
          v-model="ruleForm.navigateName"
          style="width: 350px"
        ></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="navigateIcon">
        <div class="navigate-icon" style="width: 350px">
          <!-- <el-image
            class="image-icon"
            :src="ruleForm.navigateIcon"
            style="width: 148px; height: 148px"
            fit
          ></el-image> -->
          <el-upload
            :action="url"
            list-type="picture-card"
            :accept="'.jpg, .jpeg, .png, .gif'"
            :before-upload="beforeUpload"
            :on-success="handleUploadSuccess"
            :file-list="fileList"
            :on-remove="handleRemove"
          >
            <!-- <img
              v-if="ruleForm.navigateIcon"
              :src="ruleForm.navigateIcon"
              style="width: 140px; height: 140px"
            /> -->

            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <!-- <single-upload v-model="ruleForm.navigateIcon" :isShowTip="false" style="width: 350px"></single-upload> -->
      </el-form-item>
      <el-form-item
        label="链接"
        prop="navigateUrl"
        v-if="
          (row.isDefault == 2 && message == 'docEdit') || message == 'docAdd'
        "
      >
        <el-input
          v-model="ruleForm.navigateUrl"
          style="width: 350px"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="ruleForm.isEnable" style="width: 350px"></el-switch>
      </el-form-item>
      <el-form-item
        class="dialog-form-btn-cnt"
        style="
          border-top: 1px solid #eee;
          padding-top: 24px;
          padding-right: 20px;
        "
      >
        <el-button type="primary" @click="submit('ruleForm')">确定</el-button>
        <el-button @click="closedialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SingleUpload from "@/components/Upload/singleUpload";
export default {
  props: ["message", "orderNum", "row", "isShow", "type"],
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        navigateName: "",
        isEnable: true,
        navigateUrl: "",
        navigateIcon: "",
        isDefault: null,
      },
      rules: {
        navigateName: [
          { required: true, message: "请输入应用名称", trigger: "blur" },
        ],
        navigateUrl: [
          { required: true, message: "请输入链接", trigger: "blur" },
        ],
        // navigateIcon: [
        //   { required: true, message: "请上传图标", trigger: "change" },
        // ],
      },
      fileList: [],
      url: this.$global.baseURL + "/aliyun/oss/upload",
    };
  },
  components: {
    SingleUpload,
  },
  watch: {
    isShow: {
      immediate: true,
      handler(newValue, oldValue) {
        this.fileList = [];
        console.log(newValue, this.message, this.type);
        if (newValue && this.message == "docAdd") {
          this.ruleForm = {};
        } else if (newValue && this.message == "docEdit") {
          this.ruleForm = JSON.parse(JSON.stringify(this.row));
          this.ruleForm.isEnable = this.row.isEnable == 1 ? true : false;
          if (this.row.navigateIcon) {
            this.fileList.push({
              url: this.row.navigateIcon,
            });
          }
          console.log(this.ruleForm);
        }
      },
    },
  },
  methods: {
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过10MB");
      }
    },
    handleUploadSuccess(response, file, fileList) {
      console.log(this.fileList);
      this.fileList = [];
      this.ruleForm.navigateIcon = file.response.result.filePath;
      let arr = [];
      arr.push({ url: this.ruleForm.navigateIcon });
      this.fileList = arr;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.navigateIcon = ""
      this.fileList = [];
    },

    close() {
      this.$emit("closeDialog");
    },
    //关闭弹窗
    closedialog() {
      this.$emit("quxiao");
    },
    submit(formName) {
      const params = { ...this.ruleForm };
      // if (this.ruleForm.navigateIcon) {
      //   params.navigateIcon = this.$global.imgUrl + this.ruleForm.navigateIcon;
      // }
      params.teamsId = JSON.parse(localStorage.currentTeam).id;

      if (params.isEnable) {
        params.isEnable = 1;
      } else {
        params.isEnable = 2;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.message == "docAdd") {
            params.type = this.type;
            params.isDefault = 2;
            params.orderNum = this.orderNum + 1;

            console.log(params);
            this.$httpAes({
              url: this.$http.adornUrl("/teamNavigate/add"),
              method: "post",
              params,
            }).then((res) => {
              if (res.data.status) {
                this.$message({
                  type: "success",
                  message: "成功",
                  duration: 1500,
                  onClose: () => {
                    this.close();
                  },
                });
              }
            });
          } else if (this.message == "docEdit") {
            if (this.row.isDefault == 1 || this.row.isDefault == null) {
              delete params.navigateUrl;
            }
            this.$http({
              url: this.$http.adornUrl("/teamNavigate/update"),
              method: "post",
              params,
            }).then((res) => {
              if (res.data.status) {
                this.$message({
                  type: "success",
                  message: "成功",
                  duration: 1500,
                  onClose: () => {
                    this.close();
                  },
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigate-icon {
  display: flex;
  .image-icon {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    margin-right: 20px;
  }
}
</style>
